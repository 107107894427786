/*
 |-----------------------------------------------------------------------------
 | components/molecules/SocialMedia/SocialMedia.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';

.base {
	li {
		&:not(:last-child) {
			margin-bottom: div($grid-gap, 3);
		}
	}
}

.external {
	@include transition('opacity');
	fill: $brand-4;
	margin-left: div($grid-gap, 8);
	opacity: 0;
}

.platform {
	@include flex($column: 'center', $inline: true);
	@include hyperlink($brand-4);

	&:hover {
		.external {
			opacity: 1;
		}
	}

	&:focus {
		// TODO: State required
	}

	&:active {
		// TODO: State required
	}
}

.body {
	composes: base;

	.external {
		@include icon($size: 16px);
	}

	.platform {
		font-size: type(20px, $font-size-body);
	}
}

.footer {
	composes: base;

	.external {
		@include icon($size: 12px);
	}

	.platform {
		@include font($size: rems(15px), $weight: 'medium');
	}
}
